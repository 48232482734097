import http from '@/config/api';
import { getSubdomainAndDirectory } from '@/helpers/common';

export default {
  async createSettingPoint(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return await http.post(`/event/${subdomain}/child/${directory}/point/setting`, payload).then(response => response?.data);
  },
  async updateSettingPoint(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return await http.post(`/event/${subdomain}/child/${directory}/point/setting/${payload.pointId}`, payload).then(response => response?.data);
  },
  async getPointDetail({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`/event/${subdomain}/child/${directory}/point/setting`).then(response => {
      if (response) {
        commit('SET_CHILD_POINT_DETAIL', response.data?.list);
      }
    });
  },
  async getCategoriesList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/point/categories`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_CATEGORIES_LIST', list);
        commit('SET_CATEGORY_COUNT', count);
      }
    });
  },
  async getPointStoreList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/shops/point_setting`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_POINT_STORE_LIST', list);
        commit('SET_POINT_STORE_COUNT', count);
      }
    });
  },
  async addPointStore(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/shops/point_setting`, payload).then(response => response?.data);
  },
  async createCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/point/category`, payload)
      .then((response) => response?.data);
  },
  async updateCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/point/category/${payload.categoryId}`, payload)
      .then((response) => response?.data);
  },
  async deleteCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/point/category/${payload.categoryId}`, payload)
      .then((response) => response?.data);
  },
  async getHistoryPointList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.get(`/event/${subdomain}/child/${directory}/point/histories`, { params }).then(response => {
      if (response) {
        const { list, count, point, plannedPoint } = response.data;
        commit('SET_HISTORY_POINT_LIST', list);
        commit('SET_HISTORY_POINT_COUNT', count);
        commit('SET_HISTORY_POINT', point);
        commit('SET_HISTORY_PLANNED_POINT', plannedPoint);
        return response?.data;
      }
    });
  },
  async getHistoryPointCSV({ state }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http
      .post(`event/${subdomain}/child/${directory}/point/histories/export`, {
        ...params,
        orderBy: state.sortText,
        sortOrder: state.sortType,
      }).then((response) => response?.data);
  },
  async getAggregatePoint({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    await http.get(`/event/${subdomain}/child/${directory}/point/aggregate`, { params }).then(response => {
      if (response) {
        commit('SET_AGGREGRATE_POINT_DETAIL', response.data);
      }
    });
  },
  createPointQR(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/point/qrcode`, payload).then(response => response?.data?.id);
  },
  updatePointQR(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/point/qrcode/${payload.id}`, payload).then(response => response?.data?.id);
  },
  getPointQRDetail({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/point/qrcode/${params.id}`).then(response => {
      if (response) {
        commit('SET_CHILD_POINT_QR_DETAIL', response.data);
        return response.data;
      }
    });
  },
  getPointQRList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/point/qrcodes`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_POINT_QR_LIST', list);
        commit('SET_POINT_QR_COUNT', count);
        return response?.data;
      }
    });
  },
  removePointQRs(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/point/qrcodes`, {
      data: {
        ...payload
      }
    });
  },
  updatePointQrStatus(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(
      `event/${subdomain}/child/${directory}/point/qrcode/${payload.id}/status`,
      payload.data
    );
  },
  createPaymentManual(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/point/manual/payments`, payload)
  },
  createChargeManual(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/point/manual/charges`, payload)
  },
  importManualCsv(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/point/importPointPrivate`, payload, { isMultipartContent: true });
  },
  async getChildReleaseOtpList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/point/release_otps`, { params: { ...payload } }).then((response) => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_RELEASE_OTP_LIST', list);
        commit('SET_RELEASE_OTP_COUNT', count);
      }
    });
  },
  getChildAllQrTicketList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/qrpoint/release_otp`, { params }).then(response => {
      if (response) {
        commit('SET_ALL_QR_TICKET_LIST', (response.data?.data || []));
        commit('SET_ALL_QR_TICKET_COUNT', (response.data?.data?.length || 0));
        return response?.data?.data;
      }
    });
  },
  async createChildReleaseOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/point/release_otp`, {
      ...payload.data,
      hasOTP: true,
    }).then((response) => response?.data);
  },
  async deleteChildReleaseOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/point/release_otps`, {
      data: {
        ...payload
      }
    }).then((response) => response?.data);
  },
  async downloadChildReleaseOtpList(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return await http.post(`event/${subdomain}/child/${directory}/point/otps/export`, payload)
      .then((response) => response?.data)
  },
  async getChildOtpList({ commit }, payload) {
    if(!payload.releaseOtpId) return;
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/point/release_otp/${payload.releaseOtpId}`, {
      params: {
        ...payload,
      }
    }).then((response) => {
      if (response) {
        const { list, count, inactiveCount } = response.data;
        commit('SET_OTP_LIST', list);
        commit('SET_OTP_COUNT', count);
        commit('SET_OTP_INACTIVE_COUNT', inactiveCount);
      }
    });
  },
  async disableChildOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(
      `event/${subdomain}/child/${directory}/point/otps/status`, {
        ...payload,
        directory: directory,
      }
    );
  },
}
