import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialChildPoint = () => ({
  categoriesList: {
    list: [],
    count: 0,
  },
  childPointDetail: {},
  customerList: {
    list: [],
    count: 0,
  },
  historyPointList: {
    list: [],
    count: 0,
    point: 0,
    plannedPoint: 0
  },
  pointStoreList: {
    list: [],
    count: 0
  },
  aggregratePointDetail: {},
  pointQRList: {
    list: [],
    count: 0
  },
  pointQrDetail: {},
  sortText: '',
  sortType: '',
  releaseOtpList: {
    list: [],
    count: 0
  },
  allQrTicketList: {
    list: [],
    count: 0
  },
  otpList: {
    list: [],
    count: 0,
    inactiveCount: 0,
  },
});

export default {
  state: initialChildPoint(),
  mutations,
  actions,
  getters,
  namespaced: true
};
