import http from '@/config/api';
import router from '@/router';

export default {
  async createSettingPoint(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/point/setting`, payload).then(response => response?.data);
  },
  async updateSettingPoint(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return await http.post(`/event/${subdomain}/point/setting/${payload.pointId}`, payload).then(response => response?.data);
  },
  async getPointDetail({ commit, rootGetters }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain || rootGetters['common/subdomain'];
    await http.get(`/event/${subdomain}/point/setting`).then(response => {
      if (response) {
        commit('SET_PARENT_POINT_DETAIL', response.data?.list);
      }
    });
  },
  async getCategoriesList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`/event/${subdomain}/point/categories`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_CATEGORIES_LIST', list);
        commit('SET_CATEGORY_COUNT', count);
      }
    });
  },
  async getPointStoreList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`/event/${subdomain}/shops/point_setting`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_POINT_STORE_LIST', list);
        commit('SET_POINT_STORE_COUNT', count);
      }
    });
  },
  async addPointStore(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/shops/point_setting`, payload).then(response => response?.data);
  },
  async createCategory(_, payload) {
    return http.post(`/event/${payload.subdomain}/point/category`, payload)
      .then((response) => response?.data);
  },
  async updateCategory(_, payload) {
    return http.post(`/event/${payload.subdomain}/point/category/${payload.categoryId}`, payload)
      .then((response) => response?.data);
  },
  async deleteCategory(_, payload) {
    return http.delete(`/event/${payload.subdomain}/point/category/${payload.categoryId}`, payload)
      .then((response) => response?.data);
  },
  async getHistoryPointList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    return await http.get(`/event/${subdomain}/point/histories`, { params }).then(response => {
      if (response) {
        const { list, count, point, plannedPoint } = response.data;
        commit('SET_HISTORY_POINT_LIST', list);
        commit('SET_HISTORY_POINT_COUNT', count);
        commit('SET_HISTORY_POINT', point);
        commit('SET_HISTORY_PLANNED_POINT', plannedPoint);
        return response?.data;
      }
    });
  },
  async getPointQrCodePullDown({ commit, rootGetters }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain || rootGetters['common/subdomain'];
    return await http.get(`/event/${subdomain}/pointQrCodes`, { params }).then(response => {
      if (response) {
        const items = [];
        response?.data?.list.forEach((category) => {
          items.push({
            id: category.id,
            name: category.name
          });
          if (category?.qrs?.length) {
            category?.qrs.forEach((qr) => {
              items.push({
                id: qr.id,
                name: qr.name,
                isChild: true
              })
            })
          }
        });
        commit('SET_POINT_QR_PULLDOWN', items);
      }
    });
  },
  async getHistoryPointTypeList({ commit, rootGetters }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain || rootGetters['common/subdomain'];
    return await http.get(`/event/${subdomain}/point_types/all`, { params }).then(response => {
      if (response) {
        commit('SET_HISTORY_POINT_TYPE_LIST', response.data?.data);
      }
    });
  },
  async getShopPointList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    return await http.get(`/event/${subdomain}/shops/all`, { params }).then(response => {
      if (response) {
        commit('SET_SHOP_POINT_LIST', response.data?.data);
      }
    });
  },
  async getHistoryPointCSV({ state }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    return await http
      .post(`event/${subdomain}/point/histories/export`, {
        ...params,
        orderBy: state.sortText,
        sortOrder: state.sortType,
      }).then((response) => response?.data);
  },
  async getAggregatePoint({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`/event/${subdomain}/point/aggregate`, { params }).then(response => {
      if (response) {
        commit('SET_AGGREGRATE_POINT_DETAIL', response.data);
      }
    });
  },
  async getCustomerPointActivities({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`/event/${subdomain}/customer/${params.customerId}/point/activities`, { params })
      .then(response => {
        if (response) commit('SET_CUSTOMER_POINT_ACTIVITIES', response?.data);
      })
  },
  createPointQR(_, payload) {
    return http.post(`/event/${payload.subdomain}/point/qrcode`, payload).then(response => response?.data?.id);
  },
  updatePointQR(_, payload) {
    return http.post(`/event/${payload.subdomain}/point/qrcode/${payload.id}`, payload).then(response => response?.data?.id);
  },
  getPointQRDetail({ commit }, params) {
    return http.get(`/event/${params.subdomain}/point/qrcode/${params.id}`).then(response => {
      if (response) {
        commit('SET_PARENT_POINT_QR_DETAIL', response.data);
        return response.data;
      }
    });
  },
  getPointQRList({ commit }, params) {
    return http.get(`/event/${params.subdomain}/point/qrcodes`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_POINT_QR_LIST', list);
        commit('SET_POINT_QR_COUNT', count);
        return response?.data;
      }
    });
  },
  removePointQRs(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.delete(`/event/${subdomain}/point/qrcodes`, {
      data: {
        ...payload
      }
    });
  },
  updatePointQrStatus(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(
      `event/${subdomain}/point/qrcode/${payload.id}/status`,
      payload.data
    );
  },
  createPaymentManual(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/point/manual/payments`, payload)
  },
  createChargeManual(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/point/manual/charges`, payload)
  },
  importManualCsv(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/point/importPoint`, payload, { isMultipartContent: true });
  },
  async getParentReleaseOtpList({ commit }, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`event/${subdomain}/point/release_otps`, { params: { ...payload } }).then((response) => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_RELEASE_OTP_LIST', list);
        commit('SET_RELEASE_OTP_COUNT', count);
      }
    });
  },
  getParentAllQrTicketList({ commit }, params) {
    const subdomain = params?.subdomain || router.currentRoute.params.subdomain;
    return http.get(`/event/${subdomain}/qrpoint/release_otp`, { params }).then(response => {
      if (response) {
        commit('SET_ALL_QR_TICKET_LIST', (response.data?.data || []));
        commit('SET_ALL_QR_TICKET_COUNT', (response.data?.data?.length || 0));
        return response?.data?.data;
      }
    });
  },
  async createParentReleaseOtp(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(`event/${subdomain}/point/release_otp`, {
      ...payload.data,
      hasOTP: true,
    }).then((response) => response?.data);
  },
  async deleteParentReleaseOtp(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.delete(`/event/${subdomain}/point/release_otps`, {
      data: {
        ...payload
      }
    }).then((response) => response?.data);
  },
  async downloadParentReleaseOtpList(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return await http.post(`event/${subdomain}/point/otps/export`, payload)
      .then((response) => response?.data)
  },
  async getParentOtpList({ commit }, payload) {
    if(!payload.releaseOtpId) return;
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    await http.get(`event/${subdomain}/point/release_otp/${payload.releaseOtpId}`, {
      params: {
        ...payload,
      }
    }).then((response) => {
      if (response) {
        const { list, count, inactiveCount } = response.data;
        commit('SET_OTP_LIST', list);
        commit('SET_OTP_COUNT', count);
        commit('SET_OTP_INACTIVE_COUNT', inactiveCount);
      }
    });
  },
  async disableParentOtp(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    return http.post(
      `event/${subdomain}/point/otps/status`, {
        ...payload,
      }
    );
  },
}
