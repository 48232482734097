export default {
  SET_CATEGORIES_LIST(state, list) {
    state.categoriesList.list = list;
  },
  SET_CATEGORY_COUNT(state, count) {
    state.categoriesList.count = count;
  },
  SET_CHILD_POINT_DETAIL(state, list) {
    state.childPointDetail = list;
  },
  SET_CUSTOMER_LIST(state, list) {
    state.customerList.list = list;
  },
  SET_COUNT_CUSTOMER_LIST(state, count) {
    state.customerList.count = count;
  },
  SET_HISTORY_POINT_LIST(state, list) {
    state.historyPointList.list = list;
  },
  SET_HISTORY_POINT_COUNT(state, count) {
    state.historyPointList.count = count;
  },
  SET_HISTORY_POINT(state, point) {
    state.historyPointList.point = point;
  },
  SET_HISTORY_PLANNED_POINT(state, plannedPoint) {
    state.historyPointList.plannedPoint = plannedPoint;
  },
  SET_POINT_STORE_LIST(state, list) {
    state.pointStoreList.list = list;
  },
  SET_POINT_STORE_COUNT(state, count) {
    state.pointStoreList.count = count;
  },
  SET_AGGREGRATE_POINT_DETAIL(state, list) {
    state.aggregratePointDetail = list;
  },
  SET_POINT_QR_LIST(state, list) {
    state.pointQRList.list = list;
  },
  SET_POINT_QR_COUNT(state, count) {
    state.pointQRList.count = count;
  },
  SET_CHILD_POINT_QR_DETAIL(state, pointQrDetail) {
    state.pointQrDetail = pointQrDetail;
  },
  SET_SORT_TEXT(state, payload) {
    state.sortText = payload;
  },
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload;
  },
  SET_RELEASE_OTP_LIST(state, list) {
    state.releaseOtpList.list = list;
  },
  SET_RELEASE_OTP_COUNT(state, count) {
    state.releaseOtpList.count = count;
  },
  SET_ALL_QR_TICKET_LIST(state, list) {
    state.allQrTicketList.list = list;
  },
  SET_ALL_QR_TICKET_COUNT(state, count) {
    state.allQrTicketList.count = count;
  },
  RESET_ALL_QR_TICKET_LIST(state) {
    state.allQrTicketList.list = [];
    state.allQrTicketList.count = 0;
  },
  SET_OTP_LIST(state, list) {
    state.otpList.list = list;
  },
  SET_OTP_COUNT(state, count) {
    state.otpList.count = count;
  },
  SET_OTP_INACTIVE_COUNT(state, inactiveCount) {
    state.otpList.inactiveCount = inactiveCount;
  },
  RESET_OTP_LIST(state) {
    state.otpList.list = [];
    state.otpList.count = 0;
  },
};
