export class CommonConstants {
  static PHONE_VALIDATION_REGEX = /^0\d{9,10}$/;
  static KATAKANA_VALIDATION_REGEX = /^([ァ-ヶー\s]+)$/;
  static NUMBER_VALIDATION_REGEX = /[^0-9]/g;
  static NUMBER_AND_C_VALIDATION_REGEX = /^[0-9c\s]*$/;
  static DECIMAL_VALIDATION_REGEX = /^\d*\.?\d*$/;
  static DECIMAL_REGEX = /[^\d.]/g;
  static NUMBER_HALFSIZE_AND_COMMA_REGEX = /^[0-9,]+$/;
  static NUMBER_HALFSIZE_AND_HYPEN_REGEX = /^[0-9-]+$/;
  static ALPHANUM_AND_SPECIAL_FULLSIZE_REGEX = /[^！-～ａ-ｚＡ-Ｚ０-９ぁ-んァ-ン一-龥。、￥（）［］｛｝、。「」〜・＄＠＊＃＆＋ー￠￡￦]+/g;
  static ALPHABET_NUMERIC_VALIDATION_REGEX = /^(([a-zA-Z0-9]))*$/;
  static ALPHANUM_SPECIAL_VALIDATION_REGEX = /^([a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\\/`'"\-=|]*)$/;
  static ALPHABET_NUMERIC_UNDERSCORE_VALIDATION_REGEX = /^(([a-zA-Z0-9_]))*$/;
  static ALPHABET_HIRAGANA_REGEX = /[^ぁ-ん]+/g;
  static ALPHABET_HIRAGANA_VALIDATION_REGEX = /^[ぁ-ん]$/;
  static NUMBER_LOWERCASE_DASH_VALIDATION_REGEX = /^(([a-z0-9-]))*$/;
  static NUMBER_CHARACTER_AND_HYPHEN_VALIDATION_REGEX = /^(([a-zA-Z0-9-]))*$/;
  static NOT_CONSECUTIVE_DASHES_VALIDATION_REGEX = /^((?!--).)*$/;
  static ALPHANUMSPECIAL_VALIDATION_REGEX = /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_+=\[\]{}:;'"\|<>,.?])([a-zA-Z0-9`~!@#$%^&*()\-_+=\[\]{}:;'"\|<>,.?]+)$/;
  static ALPHANUM_AND_SPECIAL_VALIDATION_REGEX = /(?=.*[a-zA-Z])(?=.*[0-9])([a-zA-Z0-9`~!@#$%^&*()\-_+=\[\]{}:;'"\|<>,.?]+)$/;
  static FACEBOOK_URL_REGEX = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/;
  static TWITTER_URL_REGEX = /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/;
  static INSTAGRAM_URL_REGEX = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/;
  static LINE_URL_REGEX = /^(https?:\/\/)?((liff.|page.)?)line.me\/.*/;
  static URL_REGEX = /^(https?:\/\/)([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*\.)+[a-zA-Z]{2,}(:\d+)?(\/[-a-zA-Z\d%_.~+#]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_=]*)?$/i;
  static ZIPCODE_REGEX = /^\d{7}$/;
  static BANK_CODE_REGEX = /^\d{4}$/;
  static BRANCH_CODE_REGEX = /^\d{3}$/;
  static BANK_NUMBER_REGEX = /^\d{1,7}$/;
  static BANK_NAME_REGEX = /^([ァ-ヶー0-9A-Z().-\s]+)$/;
  static EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
  static STRIP_TAGS = /(<([^>]+)>)/gi;
  static SPACE_REGEX = /\s/g;
  static COMMA_REGEX = /,/g;
  static DASH_REGEX = /-/g;
  static LOADING_TIME = 300;
  static DATE_FORMAT = 'yyyy/M/d';
  static CUSTOM_DATE_FORMAT = 'yyyy/MM/dd';
  static DATETIME_FORMAT = 'yyyy/MM/dd HH:mm';
  static SPECIFIC_DATETIME_FORMAT = 'yyyy/MM/dd HH:mm:ss';
  static MILLISECONDS_IN_DAY = 60 * 60 * 24 * 1000;
  static TIME_SHOW_NOTIFACTION_PASSWORD = 14;
  static MAX_TYPE_INT = 2147483647;
  static REGIST_SHOP_URL = 'https://www.jp-bank.japanpost.jp/kojin/sokin/koza/kj_sk_kz_furikomi_ksk.html';
  static DEFAULT_LAT_LNG = {
    lat: 35.6762,
    lng: 139.6503,
  };
  static TYPE_HISTORY_USE = {
    CREATED_DATE: 1,
    UPDATED_DATE: 2,
    PAID_DATE: 3,
    CANCEL_DATE: 4,
    UNDO_DATE: 5,
    EXPIRED_DATE: 6,
  }
}
